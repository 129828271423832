(function(){
	var copyButton = document.querySelector(".false-link");

	function copy() {
		var text2Copy = copyButton.dataset.href;

		var input2Copy = document.createElement("input");
		input2Copy.value = text2Copy;
		document.body.appendChild(input2Copy);
		input2Copy.select();
		document.execCommand("copy");
		document.body.removeChild(input2Copy);
		copyButton.textContent += ' (Copié !)'
	}

	if ( undefined !== copyButton && null !== copyButton ) {
		copyButton.addEventListener("click", copy);
	}
})();
